import React from 'react'
import styled from 'styled-components'
import { useSpring, a } from '@react-spring/web'

export default ({ href, style, children }) => {
  const [{ y, color }, set] = useSpring(() => ({ y: 100, color: '#000' }))

  return (
    <>
      <SectionButton
        target='_parent'
        href={href}
        style={style}
        onMouseEnter={() => set({ y: 0, color: '#000' })}
        onMouseLeave={() => set({ y: 100, color: '#000' })}
      >
        <ButtonText style={{ color }}>
          {children}
        </ButtonText>
        <Glance
          style={{ transform: y.to(v => `translateY(${v}%`) }}
        />
      </SectionButton>
    </>
  )
}

const ButtonText = styled(a.span)`
    position: relative;
    z-index: 2;
`

const SectionButton = styled(a.a)`
  font-family: FFDINforPUMA-Bold;
  position: relative;
  margin-top: 0;
  background: #f3ff41;
  color: #000;
  padding: 0.9vw 1vw 0.7vw 1vw;
  font-size: 1.2vw;
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    font-size: 3.8vw;
    padding: 2vw 2vw 1.2vw 2vw;
  }
  `

const Glance = styled(a.div)`
    background: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transform: translateY(50%);
  `
