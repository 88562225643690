import React from 'react'

import { useControl } from 'react-three-gui'

export default () => {
  const ambientLightIntensity = useControl('Ambient Intensity', { type: 'number', value: 0.6, min: 0.0, max: 3.0, group: 'Lighting' })

  const directionalLightIntensity = useControl('Directional Intensity', { type: 'number', value: 0.5, min: 0.0, max: 3.0, group: 'Lighting' })
  const directionalLightPositionX = useControl('Directional Position X', { type: 'number', value: -38, min: -100.0, max: 100.0, group: 'Lighting' })
  const directionalLightPositionY = useControl('Directional Position Y', { type: 'number', value: 38, min: 0.0, max: 200.0, group: 'Lighting' })
  const directionalLightPositionZ = useControl('Directional Position Z', { type: 'number', value: 48, min: -100.0, max: 100.0, group: 'Lighting' })

  return (
    <>
      <ambientLight color={0xffffff} intensity={ambientLightIntensity} />
      <directionalLight
        color={0xffffff}
        intensity={directionalLightIntensity}
        position={[directionalLightPositionX, directionalLightPositionY, directionalLightPositionZ]}
      />

    </>
  )
}
