import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useEventListener } from './hooks/useEventListener'
import styled, { keyframes } from 'styled-components'
import { isMobile } from 'react-device-detect'

/**
 * Cursor Core
 * Replaces the native cursor with a custom animated cursor, consisting
 * of an inner and outer dot that scale inversely based on hover or click.
 *
 * @author Stephen Scaff (github.com/stephenscaff)
 *
 * @param {string} color - rgb color value
 * @param {number} outerAlpha - level of alpha transparency for color
 * @param {number} innerSize - inner cursor size in px
 * @param {number} innerScale - inner cursor scale amount
 * @param {number} outerSize - outer cursor size in px
 * @param {number} outerScale - outer cursor scale amount
 *
 */
function CursorCore ({
  innerSize = 8,
  innerScale = 0.7,
  outerScale = 5
}) {
  const cursorOuterRef = useRef()
  const cursorInnerRef = useRef()
  const requestRef = useRef()
  const previousTimeRef = useRef()
  const [coords, setCoords] = useState({ x: 0, y: 0 })
  const [isVisible, setIsVisible] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [isActiveClickable, setIsActiveClickable] = useState(false)
  const endX = useRef(0)
  const endY = useRef(0)

  // Primary Mouse Move event
  const onMouseMove = useCallback(({ clientX, clientY }) => {
    setCoords({ x: clientX, y: clientY })
    cursorInnerRef.current.style.top = clientY + 'px'
    cursorInnerRef.current.style.left = clientX + 'px'
    endX.current = clientX
    endY.current = clientY
  }, [])

  // Outer Cursor Animation Delay
  const animateOuterCursor = useCallback(
    (time) => {
      const lerpTime = 20

      if (previousTimeRef.current !== undefined) {
        coords.x += (endX.current - coords.x) / lerpTime
        coords.y += (endY.current - coords.y) / lerpTime
        cursorOuterRef.current.style.top = coords.y + 'px'
        cursorOuterRef.current.style.left = coords.x + 'px'
      }
      previousTimeRef.current = time
      requestRef.current = window.requestAnimationFrame(animateOuterCursor)
    },
    [requestRef] // eslint-disable-line
  )

  // RAF for animateOuterCursor
  useEffect(() => {
    requestRef.current = window.requestAnimationFrame(animateOuterCursor)
    return () => {
      window.cancelAnimationFrame(requestRef.current)
    }
  }, [animateOuterCursor])

  // Mouse Events State updates
  const onMouseDown = useCallback(() => {
    setIsActive(true)
  }, [])

  const onMouseUp = useCallback(() => {
    setIsActive(false)
  }, [])

  const onMouseEnterViewport = useCallback(() => {
    setIsVisible(true)
    setIsActive(true)
    setIsActive(false)
  }, [])

  const onMouseLeaveViewport = useCallback(() => {
    setIsVisible(false)
  }, [])

  useEventListener('mousemove', onMouseMove)
  useEventListener('mousedown', onMouseDown)
  useEventListener('mouseup', onMouseUp)
  useEventListener('mouseover', onMouseEnterViewport)
  useEventListener('mouseout', onMouseLeaveViewport)

  // Cursors Hover/Active State
  useEffect(() => {
    if (isActive) {
      cursorInnerRef.current.style.transform = `translateZ(0) scale(${innerScale})`
      cursorInnerRef.current.style.border = '1px solid #333333'
      cursorOuterRef.current.style.transform = 'translateZ(0) scale(0.7)'
    } else {
      cursorInnerRef.current.style.transform = 'translateZ(0) scale(1)'
      cursorInnerRef.current.style.border = 'none'
      cursorOuterRef.current.style.transform = 'translateZ(0) scale(0.8)'
    }
  }, [innerScale, outerScale, isActive])

  // Cursors Click States
  useEffect(() => {
    if (isActiveClickable) {
      cursorInnerRef.current.style.transform = `translateZ(0) scale(${
        innerScale * 1.2
      })`
      cursorOuterRef.current.style.transform = `translateZ(0) scale(${
        outerScale * 1.2
      })`
    }
  }, [innerScale, outerScale, isActiveClickable])

  // Cursor Visibility State
  useEffect(() => {
    if (isVisible) {
      cursorInnerRef.current.style.opacity = 1
      cursorOuterRef.current.style.opacity = 1
    } else {
      cursorInnerRef.current.style.opacity = 0
      cursorOuterRef.current.style.opacity = 0
    }
  }, [isVisible])

  // Target all possible clickables
  useEffect(() => {
    const clickables = document.querySelectorAll(
      'a, input[type="submit"], input[type="image"], label[for], select, button, .link, object'
    )
    clickables.forEach((el) => {
      el.style.cursor = 'none'

      el.addEventListener('mouseover', () => {
        setIsActive(true)
      })

      el.addEventListener('pointerover', () => {
        setIsActive(true)
      })

      el.addEventListener('pointerout', () => {
        setIsActive(false)
      })

      el.addEventListener('click', () => {
        setIsActive(true)
        setIsActiveClickable(false)
      })
      el.addEventListener('mousedown', () => {
        setIsActiveClickable(true)
      })
      el.addEventListener('mouseup', () => {
        setIsActive(true)
      })
      el.addEventListener('mouseout', () => {
        setIsActive(false)
        setIsActiveClickable(false)
      })
    })

    return () => {
      clickables.forEach((el) => {
        el.removeEventListener('pointerover', () => {
          setIsActive(true)
        })
        el.removeEventListener('pointerout', () => {
          setIsActive(false)
        })

        el.removeEventListener('mouseover', () => {
          setIsActive(true)
        })
        el.removeEventListener('click', () => {
          setIsActive(true)
          setIsActiveClickable(false)
        })
        el.removeEventListener('mousedown', () => {
          setIsActiveClickable(true)
        })
        el.removeEventListener('mouseup', () => {
          setIsActive(true)
        })
        el.removeEventListener('mouseout', () => {
          setIsActive(false)
          setIsActiveClickable(false)
        })
      })
    }
  }, [isActive])

  // Cursor Styles
  const styles = {
    cursorInner: {
      zIndex: 999,
      display: 'block',
      position: 'fixed',
      borderRadius: '50%',
      width: innerSize,
      height: innerSize,
      pointerEvents: 'none',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      transition: 'opacity 0.15s ease-in-out, transform 0.25s ease-in-out',
      backfaceVisibility: 'hidden',
      willChange: 'transform',
      border: 'none'
    },
    cursorOuter: {
      zIndex: 999,
      display: 'block',
      position: 'fixed',
      borderRadius: '50%',
      pointerEvents: 'none',
      marginTop: '-96px',
      marginLeft: '-95px',
      width: '200px',
      height: '200px',
      // backgroundColor: `rgba(${color}, 0.3)`,
      transition: 'opacity 0.15s ease-in-out, transform 0.15s ease-in-out',
      backfaceVisibility: 'hidden',
      willChange: 'transform',
      color: '#fff',
      fill: '#fff',
      transform: 'translateZ(0) scale(0.8)'
    }
  }

  // Hide / Show global cursor
  document.body.style.cursor = 'none'

  return (
    <>
      <div ref={cursorOuterRef} style={styles.cursorOuter}>
        <Text xmlns='http://www.w3.org/2000/svg' viewBox='0 0 120 120'>
          <text transform='translate(44.45 15.22) rotate(-13.51)'>P</text>
          <text transform='translate(54.93 12.89) rotate(-0.93)'>U</text>
          <text transform='matrix(0.97, 0.25, -0.25, 0.97, 66.33, 12.82)'>M</text>
          <text transform='matrix(0.87, 0.49, -0.49, 0.87, 79.29, 16.59)'>A</text>
          <text transform='matrix(0.77, 0.63, -0.63, 0.77, 88.7, 22.36)'> </text>
          <text transform='translate(92.66 25.48) rotate(48.5)'>F</text>
          <text transform='translate(99.38 33.21) rotate(61.53)'>U</text>
          <text transform='translate(104.6 43.34) rotate(74.5)'>T</text>
          <text transform='matrix(0.05, 1, -1, 0.05, 107.19, 52.97)'>U</text>
          <text transform='matrix(-0.19, 0.98, -0.98, -0.19, 107.53, 64.36)'>R</text>
          <text transform='translate(105.22 75.3) rotate(114.09)'>E</text>
          <text transform='translate(100.58 84.81) rotate(123.53)'> </text>
          <text transform='translate(97.84 89.06) rotate(132.46)'>Z</text>
          <text transform='translate(91.02 96.05) rotate(141.37)'> </text>
          <text transform='matrix(-0.86, 0.5, -0.5, -0.86, 87.1, 99.23)'>•</text>
          <text transform='matrix(-0.93, 0.37, -0.37, -0.93, 79.24, 103.5)'> </text>
          <text transform='translate(74.6 105.47) rotate(168.03)'>P</text>
          <text transform='translate(64.03 107.58) rotate(-178.69)'>U</text>
          <text transform='translate(52.66 107.29) rotate(-163.7)'>M</text>
          <text transform='matrix(-0.86, -0.52, 0.52, -0.86, 39.83, 103.18)'>A</text>
          <text transform='translate(30.57 97.15) rotate(-139.25)'> </text>
          <text transform='translate(26.7 93.94) rotate(-130)'>F</text>
          <text transform='translate(20.18 86.03) rotate(-116.95)'>U</text>
          <text transform='translate(15.23 75.78) rotate(-103.98)'>T</text>
          <text transform='matrix(-0.02, -1, 1, -0.02, 12.9, 66.07)'>U</text>
          <text transform='matrix(0.22, -0.98, 0.98, 0.22, 12.86, 54.68)'>R</text>
          <text transform='translate(15.47 43.8) rotate(-64.38)'>E</text>
          <text transform='matrix(0.57, -0.82, 0.82, 0.57, 20.37, 34.4)'> </text>
          <text transform='translate(23.21 30.24) rotate(-46.02)'>Z</text>
          <text transform='translate(30.2 23.44) rotate(-37.14)'> </text>
          <text transform='translate(34.22 20.36) rotate(-28.66)'>•</text>
          <text transform='matrix(0.94, -0.34, 0.34, 0.94, 42.19, 16.3)'> </text>
        </Text>
      </div>
      <div ref={cursorInnerRef} style={styles.cursorInner} />
    </>
  )
}

const rotate = keyframes`
  from {
    transform-origin: center;
    transform: rotate(0deg);
  }

  to {
    transform-origin: center;
    transform: rotate(360deg);
  }
`

const Text = styled.svg`
  animation: ${rotate} 15s linear infinite;
  `

/**
 * AnimatedCursor
 * Calls and passes props to CursorCore if not a touch/mobile device.
 */
function AnimatedCursor ({
  innerSize = 15,
  outerScale = 0.8,
  innerScale = 0.7
}) {
  if (isMobile) {
    return <></>
  }
  return (
    <CursorCore
      innerSize={innerSize}
      innerScale={innerScale}
      outerScale={outerScale}
    />
  )
}

export default AnimatedCursor
