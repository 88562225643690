import { createContext } from 'react'

const InteractionContext = createContext({
  interacted: false,
  setInteracted: () => {},
  isGrabbed: false,
  setIsGrabbed: () => {}
})

export { InteractionContext }
