import _extends from '@babel/runtime/helpers/esm/extends'
import { createElement, useEffect, useState } from 'react'
import { useProgress } from '@react-three/drei'
import { useTransition, a } from '@react-spring/web'
import { isMobile } from 'react-device-detect'

import crazyPath from '../assets/images/crazy-preloader.gif'

const total = 6 // edit this if new assets added to loader

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 998
  },
  img: {
    position: 'absolute',
    zIndex: 1
  },
  data: {
    fontFamily: 'FFDINforPUMA-Bold',
    zIndex: 0,
    display: 'inline-block',
    position: 'relative',
    fontVariantNumeric: 'tabular-nums',
    color: '#111111',
    fontSize: '13.8rem',
    lineHeight: '0',
    padding: '2.2rem 0 0',
    whiteSpace: 'nowrap'
  }
}

if (isMobile) {
  styles.data.fontSize = '8rem'
}

function Loader ({
  dataInterpolation = p => Math.round(p * 100) + '%',
  initialState = active => active
}) {
  const { loaded } = useProgress()

  const [progress, setProgress] = useState(0)
  const [active, setActive] = useState(true)

  useEffect(() => {
    setProgress(loaded / total * 100)
  }, [setProgress, loaded])

  useEffect(() => {
    if (progress >= 100) {
      setActive(false)
    }
  }, [progress, setActive])

  const transition = useTransition(initialState(active), {
    from: {
      opacity: 1,
      progress: 0
    },
    leave: {
      opacity: 0
    },
    update: {
      progress: progress / 100
    }
  })

  return transition(
    ({
      progress,
      opacity
    }, active) =>
      active &&
      createElement(
        a.div,
        {
          style: _extends({}, styles.container, { opacity })
        },
        createElement(
          a.img,
          {
            src: crazyPath,
            style: styles.img
          }
        ),
        createElement(
          a.span,
          {
            style: styles.data
          },
          progress.to(dataInterpolation)
        )
      )
  )
}

export { Loader }
