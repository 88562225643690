export const languageStrings = {
  en: {
    lock: 'Lock',
    explode: 'Explode',
    play: 'Play',
    lockDescription: 'FUZIONFIT+ compression band for fit and agility',
    explodeDescription: 'Dynamic Motion System outsole for traction',
    playDescription: 'GripControl Pro for refined touch and ball control',
    buyButton: 'SHOP NOW',
    swipe: 'SWIPE',
    buyNowLink: 'https://go.puma.com/futureziframe'
  },
  us: {
    lock: 'Lock',
    explode: 'Explode',
    play: 'Play',
    lockDescription: 'FUZIONFIT+ compression band for fit and agility',
    explodeDescription: 'Dynamic Motion System outsole for traction',
    playDescription: 'GripControl Pro for refined touch and ball control',
    buyButton: 'SHOP NOW',
    swipe: 'SWIPE',
    buyNowLink: 'https://us.puma.com/en/us/pd/future-z-1.1-fg%2Fag-mens-soccer-cleats/106028.html'
  },
  in: {
    lock: 'Lock',
    explode: 'Explode',
    play: 'Play',
    lockDescription: 'FUZIONFIT+ compression band for fit and agility',
    explodeDescription: 'Dynamic Motion System outsole for traction',
    playDescription: 'GripControl Pro for refined touch and ball control',
    buyButton: 'SHOP NOW',
    swipe: 'SWIPE',
    buyNowLink: 'https://in.puma.com/in/en/pd/future-z-1.1-fg%2Fag-mens-football-boots/106028.html?dwvar_106028_color=01'
  },
  de: {
    lock: 'ZIEHE FEST',
    explode: 'LEGE LOS',
    play: 'SPIELE',
    lockDescription: 'FUZIONFIT+ Kompressionsband für Passform und Agilität',
    explodeDescription: 'Dynamic Motion System Außensohle für mehr Traktion',
    playDescription: 'GripControl Pro für besseren Touch und präzise Ballkontrolle',
    buyButton: 'JETZT KAUFEN',
    swipe: 'SWIPE',
    buyNowLink: 'https://go.puma.com/futureziframe'
  },
  fr: {
    lock: 'SERREZ',
    explode: 'EXPLOSEZ',
    play: 'JOUEZ',
    lockDescription: 'Bande de compression FUZIONFIT+ pour un meilleur ajustement et plus d\'agilité',
    explodeDescription: 'Semelle extérieure Dynamic Motion System pour plus de traction',
    playDescription: 'GripControl Pro pour un meilleur ressenti et contrôle du ballon',
    buyButton: 'ACHETER MAINTENANT',
    swipe: 'SWIPE',
    buyNowLink: 'https://go.puma.com/futureziframe'
  },
  it: {
    lock: 'BLOCCA',
    explode: 'ESPLODI',
    play: 'GIOCA',
    lockDescription: 'Fascia di compressione FUZIONFIT+ per una migliore aderenza e agilità',
    explodeDescription: 'Suola conSistema di movimento dinamico per una maggiore trazione',
    playDescription: 'GripControl Pro per un tocco e controllo di palla preciso',
    buyButton: 'ACQUISTA ORA',
    swipe: 'SWIPE',
    buyNowLink: 'https://go.puma.com/futureziframe'
  },
  es: {
    lock: 'AJÚSTELAS',
    explode: 'LIBÉRESE',
    play: 'JUEGUE',
    lockDescription: 'Cinta de compresión FUZIONFIT+ que se ajusta y potencia la agilidad',
    explodeDescription: 'Suela exterior Dynamic Motion System que aporta tracción',
    playDescription: 'GripControl Pro para perfeccionar el toque y el control de la pelota',
    buyButton: 'COMPRAR AHORA',
    swipe: 'SWIPE',
    buyNowLink: 'https://go.puma.com/futureziframe'
  },
  nl: {
    lock: 'AANTREKKEN',
    explode: 'ALLES GEVEN',
    play: 'SPELEN',
    lockDescription: 'FUZIONFIT+ compression band for fit and agility',
    explodeDescription: 'Dynamic Motion System outsole for traction',
    playDescription: 'GripControl Pro for refined touch and ball control',
    buyButton: 'SHOP NOW',
    swipe: 'SWIPE',
    buyNowLink: 'https://go.puma.com/futureziframe'
  },
  pl: {
    lock: 'ZACIŚNIJ',
    explode: 'UWOLNIJ ENERGIĘ',
    play: 'GRAJ',
    lockDescription: 'FUZIONFIT+ – taśma ściskająca zapewniająca dopasowanie i zwinność',
    explodeDescription: 'Dynamic Motion System – podeszwa zapewniająca przyczepność',
    playDescription: 'GripControl Pro – niezakłócony zmysł dotyku i kontrola nad piłką',
    buyButton: 'KUP TERAZ',
    swipe: 'SWIPE',
    buyNowLink: 'https://go.puma.com/futureziframe'
  }
}
