import React, { Suspense, useEffect, useState } from 'react'
import { Plane, useDetectGPU } from '@react-three/drei'
import styled from 'styled-components'
import { Controls } from 'react-three-gui'
import { useLocation, Switch, Route } from 'wouter'
import { a, Spring } from '@react-spring/web'
import AnimatedCursor from './components/AnimatedCursor/AnimatedCursor'
import { isMobile } from 'react-device-detect'

// i18n
import { languageStrings } from './Language'

// context
import { LanguageContext, Languages } from './state/LanguageContext'
import { InteractionContext } from './state/InteractionContext'

// components
import { Loader } from './components/Loader'
import ParentGroup from './components/ParentGroup'
import Lights from './components/Lights'
import Boot from './components/Boot'
import Video from './components/Video'
import Crazy from './components/Crazy'
import FutureZ from './components/FutureZ'
import Swipe from './components/Swipe'
import Button from './components/Button'

import swipePath from './assets/images/Swipe_Icon_01.svg'

const urlParams = new URLSearchParams(window.location.search)
const urlLanguage = urlParams.get('language')

export default () => {
  const [location, setLocation] = useLocation()

  const GPUTier = useDetectGPU()

  // default to start of experience
  useEffect(() => {
    setLocation('/')
  }, [setLocation])

  const [interacted, setInteracted] = useState(false)
  const [isGrabbed, setIsGrabbed] = useState(false)
  const interactionProviderValue = { interacted, setInteracted, isGrabbed, setIsGrabbed }

  let defaultLanguage = Languages[0]
  if (urlLanguage !== null && Languages.includes(urlLanguage)) {
    defaultLanguage = urlLanguage
  }

  const [language, setLanguage] = useState(defaultLanguage)
  const languageProviderValue = { language, setLanguage }

  return (
    <>
      <AnimatedCursor />
      <Controls.Provider>
        <Controls.Canvas
          // style={{ touchAction: 'none' }}
          // shadowMap
          gl={{ antialias: GPUTier && GPUTier.tier > 1 }}
          colorManagement={false}
          onCreated={gl => {
            // gl.physicallyCorrectLights = true
            // gl.gl.toneMapping = ACESFilmicToneMapping
            // gl.gl.gammaFactor = 2.2
          }}
          camera={{ fov: 45, position: [0, 5, 30] }}
        >
          {!isMobile && (
            <Plane
              args={[1000, 1000]} onPointerDown={(e) => {
                e.stopPropagation()
                if (interacted) {
                  setLocation('/')
                }
              }}
            >
              <meshBasicMaterial transparent opacity={0.0} attach='material' />
            </Plane>
          )}
          <Suspense fallback={null}>
            <ParentGroup>
              <Lights />
              <LanguageContext.Provider value={languageProviderValue}>
                <InteractionContext.Provider value={interactionProviderValue}>
                  <Switch location={location}>
                    <Route path='/'>
                      <Boot
                        rotation={isMobile ? [2.68, -0.75, -0.34] : [1.50, -0.25, -Math.PI / 2]}
                        position={isMobile ? [2, -1.45, -9.20] : [0, 1.6, 6.5]}
                      />
                    </Route>
                    <Route path='/lock'>
                      <Boot
                        rotation={isMobile ? [7.79 - (Math.PI * 2), -0.42, -1.42] : [7.79 - (Math.PI * 2), -0.75, -1.42]}
                        position={isMobile ? [2, 0.6, 1] : [-4.2, 4.2, 18.60]}
                      />
                    </Route>
                    <Route path='/explode'>
                      <Boot
                        rotation={isMobile ? [6.53 - (Math.PI * 2), 0.08, 0.42] : [6.2 - (Math.PI * 2), 0.0, 1.17]}
                        position={isMobile ? [1, -0.6, -5.8] : [6.2, 2.0, 10]}
                      />
                    </Route>
                    <Route path='/play'>
                      <Boot
                        rotation={isMobile ? [8.46 - (Math.PI * 2), 0.08, 0.84] : [8.38 - (Math.PI * 2), -0.08, 1.17]}
                        position={isMobile ? [4.4, 3.5, 1.2] : [8.8, 6.2, 18.40]}
                      />
                    </Route>
                  </Switch>
                </InteractionContext.Provider>
              </LanguageContext.Provider>
            </ParentGroup>
          </Suspense>
          {/* <Stats showPanel={0} /> */}
          {/* <OrbitControls /> */}
          {/* <Effects /> */}
        </Controls.Canvas>
        {/* <Controls
          style={{ zIndex: 9999999 }}
          title='Controls'
        /> */}
      </Controls.Provider>
      <Suspense fallback={null}>
        <InteractionContext.Provider value={interactionProviderValue}>
          <Video />
        </InteractionContext.Provider>
        <Crazy />
        <Swipe />
        <FutureZ />
        <LanguageContext.Provider value={languageProviderValue}>
          <Switch location={location}>
            <Route path='/'>
              {isMobile && (
                <Spring
                  from={{ opacity: 0 }}
                  to={{ opacity: 1 }}
                  delay={6000}
                >
                  {props => <SVGicon style={props} type='image/svg+xml' data={swipePath} />}
                </Spring>
              )}
              <SectionContentHome>
                <Spring
                  from={{ opacity: 0 }}
                  to={{ opacity: 1 }}
                  delay={6500}
                >
                  {props => <Button style={props} href={languageStrings[language].buyNowLink}>{languageStrings[language].buyButton}</Button>}
                </Spring>
              </SectionContentHome>
            </Route>
            <Route path='/lock'>
              <SectionContentLock>
                <Spring
                  from={{ opacity: 0 }}
                  to={{ opacity: 1 }}
                  delay={1200}
                >
                  {(props) => (
                    <SectionTitle style={props}>
                      <Spring
                        from={{ transform: 'translate3d(0, 100px, 0)' }}
                        to={{ transform: 'translate3d(0, 0px, 0)' }}
                        delay={1200}
                      >
                        {(props) => (
                          <a.div style={props}>
                            {languageStrings[language].lock}
                          </a.div>
                        )}
                      </Spring>
                    </SectionTitle>
                  )}
                </Spring>
                <Spring
                  from={{ opacity: 0 }}
                  to={{ opacity: 1 }}
                  delay={1400}
                >
                  {props => <SectionDescription style={props}>{languageStrings[language].lockDescription}</SectionDescription>}
                </Spring>
                <Spring
                  from={{ opacity: 0 }}
                  to={{ opacity: 1 }}
                  delay={1500}
                >
                  {props => <Button style={props} href={languageStrings[language].buyNowLink}>{languageStrings[language].buyButton}</Button>}
                </Spring>
              </SectionContentLock>
            </Route>
            <Route path='/explode'>
              <SectionContentExplode>
                <Spring
                  from={{ opacity: 0 }}
                  to={{ opacity: 1 }}
                  delay={1200}
                >
                  {(props) => (
                    <SectionTitle style={props}>
                      <Spring
                        from={{ transform: 'translate3d(0, 100px, 0)' }}
                        to={{ transform: 'translate3d(0, 0px, 0)' }}
                        delay={1200}
                      >
                        {(props) => (
                          <a.div style={props}>
                            {languageStrings[language].explode}
                          </a.div>
                        )}
                      </Spring>
                    </SectionTitle>
                  )}
                </Spring>
                <Spring
                  from={{ opacity: 0 }}
                  to={{ opacity: 1 }}
                  delay={1400}
                >
                  {props => <SectionDescription style={props}>{languageStrings[language].explodeDescription}</SectionDescription>}
                </Spring>
                <Spring
                  from={{ opacity: 0 }}
                  to={{ opacity: 1 }}
                  delay={1500}
                >
                  {props => <Button style={props} href={languageStrings[language].buyNowLink}>{languageStrings[language].buyButton}</Button>}
                </Spring>
              </SectionContentExplode>
            </Route>
            <Route path='/play'>
              <SectionContentPlay>
                <Spring
                  from={{ opacity: 0 }}
                  to={{ opacity: 1 }}
                  delay={1200}
                >
                  {(props) => (
                    <SectionTitle style={props}>
                      <Spring
                        from={{ transform: 'translate3d(0, 100px, 0)' }}
                        to={{ transform: 'translate3d(0, 0px, 0)' }}
                        delay={1200}
                      >
                        {(props) => (
                          <a.div style={props}>
                            {languageStrings[language].play}
                          </a.div>
                        )}
                      </Spring>
                    </SectionTitle>
                  )}
                </Spring>
                <Spring
                  from={{ opacity: 0 }}
                  to={{ opacity: 1 }}
                  delay={1400}
                >
                  {props => <SectionDescription style={props}>{languageStrings[language].playDescription}</SectionDescription>}
                </Spring>
                <Spring
                  from={{ opacity: 0 }}
                  to={{ opacity: 1 }}
                  delay={1500}
                >
                  {props => <Button style={props} href={languageStrings[language].buyNowLink}>{languageStrings[language].buyButton}</Button>}
                </Spring>
              </SectionContentPlay>
            </Route>
          </Switch>
        </LanguageContext.Provider>
      </Suspense>
      {/* <Nav /> */}
      <Loader />
    </>
  )
}

const SectionContentHome = styled(a.div)`
  padding: 0 0 4vw 4vw;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;

  @media (max-width: 768px) {
    right: auto;
    left: 4vw;
    bottom: 7vw;
    top: auto;
    width: 60vw;
  }
  `

const SVGicon = styled(a.object)`
  position: absolute;
  /* top: 2.1vw; */
  left: 6vw;
  width: 18vw;
  height: auto;
  z-index: 2;
  `

const SectionContentLock = styled(a.div)`
  width: 33vw;
  padding: 6vw 10vw 4.5vw 0;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;

  @media (max-width: 768px) {
    right: auto;
    left: 4vw;
    bottom: 7vw;
    top: auto;
    width: 60vw;
    padding: 4vw 0 0 4vw;
  }
  `

const SectionContentExplode = styled(a.div)`
  width: 25vw;
  padding: 4vw 0 0 4vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;

  @media (max-width: 768px) {
    right: auto;
    left: 4vw;
    bottom: 7vw;
    top: auto;
    width: 60vw;
  }
  `

const SectionContentPlay = styled(a.div)`
  width: 25vw;
  padding: 4vw 0 0 4vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;

  @media (max-width: 768px) {
    right: auto;
    left: 4vw;
    bottom: 7vw;
    top: auto;
    width: 60vw;
  }
  `

const SectionTitle = styled(a.div)`
  font-family: FFDINforPUMA-Bold;
  font-weight: bold;
  text-transform: uppercase;
  color: #f3ff41;
  letter-spacing: 0.2rem;
  font-size: 3.2vw;
  margin-bottom: 0.5vw;
  overflow: hidden;

  @media (max-width: 768px) {
    font-size: 9.2vw;
  }
  `

const SectionDescription = styled(a.div)`
  font-family: FFDINforPUMA-Regular;
  font-weight: normal;
  color: #fff;
  font-size: 1.6vw;
  margin-bottom: 1.3vw;

  @media (max-width: 768px) {
    font-size: 4vw;
    margin-bottom: 3.3vw;
  }
  `
