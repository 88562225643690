import React, { useRef, useEffect, useContext, useState } from 'react'
/* eslint-disable react-hooks/exhaustive-deps */
import { useLoader } from 'react-three-fiber'
import { useProgress } from '@react-three/drei'
import { FileLoader } from 'three'
import { isMobile } from 'react-device-detect'

import videoPath from '../assets/video/video-intro.mp4'
import videoPathM from '../assets/video/video-intro-m.mp4'

// context
import { InteractionContext } from '../state/InteractionContext'

let endTime = 7.1 // pause video at this time

export default () => {
  const { progress } = useProgress()

  const [videoStarted, setVideoStarted] = useState(false)

  const videoBlob = useLoader(FileLoader, isMobile ? videoPathM : videoPath, (loader) => {
    loader.setMimeType('video/mp4')
    loader.setResponseType('blob')
  })

  const videoRef = useRef()

  const { interacted } = useContext(InteractionContext)

  useEffect(() => {
    videoRef.current.addEventListener('timeupdate', function () {
      if (this.currentTime >= endTime) {
        this.pause()
      }
    }, false)
  }, [])

  useEffect(() => {
    if (progress === 100 && !videoStarted) {
      const vid = URL.createObjectURL(videoBlob)
      videoRef.current.src = vid
      videoRef.current.play()

      setVideoStarted(true)
    }
  }, [progress, videoBlob, videoStarted, setVideoStarted])

  useEffect(() => {
    if (interacted === true) {
      endTime = 9999
      videoRef.current.play()
    }
  }, [interacted])

  return (
    <>
      <video width='100%' height='auto' ref={videoRef} playsInline muted autoPlay={false} />
    </>
  )
}
