import React, { useEffect, useState, useContext } from 'react'
import { a, useSpring } from '@react-spring/web'
import styled from 'styled-components'
import { useProgress } from '@react-three/drei'
import { isMobile } from 'react-device-detect'

// context
import { LanguageContext } from '../state/LanguageContext'

// i18n
import { languageStrings } from '../Language'

export default () => {
  if (!isMobile) {
    return (<></>)
  }

  const { language } = useContext(LanguageContext)

  const { progress } = useProgress()
  const [ready, setReady] = useState(false)

  const [containerProps, setContainerProps] = useSpring(() => ({ opacity: 0 }))
  const [props, setProps] = useSpring(() => ({ width: '0%' }))
  const [innerProps, setInnerProps] = useSpring(() => ({ width: '0%' }))

  useEffect(() => {
    if (progress === 100) {
      setReady(true)
    }
  }, [progress])

  useEffect(() => {
    if (ready) {
      setContainerProps({ opacity: 1, delay: 900, duration: 800 })
      setProps({ width: '100%', delay: 900, duration: 800 })
      setInnerProps({ width: '100%', delay: 1050, duration: 800 })

      setContainerProps({ opacity: 0, delay: 3200 })
    }
  }, [ready, setProps, setContainerProps, setInnerProps])

  return (
    <>
      <SwipeButtonContainer style={{ ...containerProps }}>
        <SwipeButton style={{ ...props }}>
          <InnerText style={{ ...innerProps }}>{languageStrings[language].swipe}</InnerText>
        </SwipeButton>
      </SwipeButtonContainer>
    </>
  )
}

const SwipeButtonContainer = styled(a.div)`
  position: absolute;
  bottom: 15vw;
  width: 50vw;
  z-index: 1;
  /* opacity: 1!important; */
  display: flex;
  justify-content: flex-end;
`

const SwipeButton = styled(a.div)`
  width: 0%;
  color: #fff;
  font-size: 1.5rem;
  letter-spacing: 0.3rem;
  border: 3px solid #fff;
  border-radius: 30px;
  padding: 0.4rem 2rem 0.4rem 2rem;
  display: flex;
  justify-content: flex-end;
`

const InnerText = styled(a.div)`
    display: flex;
    justify-content: center;
    overflow: hidden;
`
