import React, { useRef, useMemo, useEffect } from 'react'
import { useFrame, useThree } from 'react-three-fiber'
import { isMobile } from 'react-device-detect'
import { Euler, Quaternion } from 'three'
import { useLocation } from 'wouter'

export default ({ children }) => {
  const { mouse } = useThree()
  const [rEuler, rQuaternion] = useMemo(() => [new Euler(), new Quaternion()], [])
  const parentGroup = useRef()

  // location
  const [location] = useLocation()

  const sceneRotationSpeed = 0.005

  const sceneRotationAmountX = useRef(0.0)
  const sceneRotationAmountY = useRef(0.0)

  useEffect(() => {
    switch (location) {
      case '/':
        sceneRotationAmountX.current = 0.06
        sceneRotationAmountY.current = 0.06
        break

      case '/lock':
        sceneRotationAmountX.current = 0.02
        sceneRotationAmountY.current = 0.02

        break

      case '/play':
        sceneRotationAmountX.current = 0.02
        sceneRotationAmountY.current = 0.02
        break

      case '/explode':
        sceneRotationAmountX.current = 0.025
        sceneRotationAmountY.current = 0.025
        break

      default:
        break
    }
  }, [location])

  useFrame(() => {
    if (isMobile) {
      return
    }

    // rotate mesh on mouse move
    if (parentGroup.current) {
      rEuler.set((-mouse.y * Math.PI) * sceneRotationAmountY.current, (mouse.x * Math.PI) * sceneRotationAmountX.current, 0)
      parentGroup.current.quaternion.slerp(rQuaternion.setFromEuler(rEuler), sceneRotationSpeed)
    }
  })

  return (
    <group ref={parentGroup}>
      {children}
    </group>
  )
}
