import { createContext } from 'react'

const Languages = ['en', 'us', 'de', 'fr', 'it', 'es', 'nl', 'pl', 'in']

const LanguageContext = createContext({
  language: Languages[0],
  setLanguage: () => {}
})

export { LanguageContext, Languages }
