import React, { useEffect, useState, useContext, useRef } from 'react'
/* eslint-disable react-hooks/exhaustive-deps */
import { a, useSpring, config } from '@react-spring/web'
import { a as am } from '@react-spring/three'
import styled from 'styled-components'
import { Html, Sphere } from '@react-three/drei'
// import { useControl } from 'react-three-gui'
import { useLocation } from 'wouter'
import { isMobile, isSafari } from 'react-device-detect'

import playImgPath from '../assets/images/play.svg'
import playImgPathSafari from '../assets/images/PLAY_arrows_outter_anim_01-js.svg'

import playCopy from '../assets/images/PLAY_COPY_anim_Animated_01.svg'
import explodeCopy from '../assets/images/EXPLODE_COPY_anim_Animated_01.svg'
import lockCopy from '../assets/images/LOCK_COPY_anim_Animated_01.svg'

import pointAnimPath from '../assets/images/point-anim.svg'

import explodeImgPath from '../assets/images/explode.svg'
import explodeImgPathSafari from '../assets/images/EXPLODE_arrows_outter_anim_01-js.svg'

import lockImgPath from '../assets/images/lock.svg'
import lockImgPathSafari from '../assets/images/LOCK_arrows_outter_anim_01-js.svg'

// context
import { InteractionContext } from '../state/InteractionContext'

export default ({ isAnimating }) => {
  const AHtml = a(Html)

  // refs
  const svgPlayRef = useRef()
  const svgPlayTextRef = useRef()
  const svgPlayTextElRef = useRef()
  const svgPlayPointAnimRef = useRef()
  const svgPlayPointAnimSVGRef = useRef()

  const svgExplodeRef = useRef()
  const svgExplodeTextRef = useRef()
  const svgExplodeTextElRef = useRef()
  const svgExplodePointAnimRef = useRef()
  const svgExplodePointAnimSVGRef = useRef()

  const svgLockRef = useRef()
  const svgLockTextRef = useRef()
  const svgLockTextElRef = useRef()
  const svgLockPointAnimRef = useRef()
  const svgLockPointAnimSVGRef = useRef()

  const rayCastObjectScale = useRef([1.2, 1.2, 1.2])

  // location
  const [location, setLocation] = useLocation()

  // context
  const { setInteracted, isGrabbed } = useContext(InteractionContext)

  const [lineAnimating, setLineAnimating] = useState(false)

  // const posX = useControl('Pos X', { type: 'number', value: -0.6, min: -6, max: 6, group: 'Position' })
  // const posY = useControl('Pos Y', { type: 'number', value: -1.3, min: -6, max: 6, group: 'Position' })
  // const posZ = useControl('Pos Z', { type: 'number', value: 0, min: -6, max: 6, group: 'Position' })

  const [buttonProps1, setButtonProps1] = useSpring(() => ({ opacity: 0, config: config.stiff }))
  const [buttonProps2, setButtonProps2] = useSpring(() => ({ opacity: 0, config: config.stiff }))
  const [buttonProps3, setButtonProps3] = useSpring(() => ({ opacity: 0, config: config.stiff }))

  const [buttonTextProps1, setButtonTextProps1] = useSpring(() => ({ opacity: 0, config: config.stiff }))
  const [buttonTextProps2, setButtonTextProps2] = useSpring(() => ({ opacity: 0, config: config.stiff }))
  const [buttonTextProps3, setButtonTextProps3] = useSpring(() => ({ opacity: 0, config: config.stiff }))

  const [playButtonPosition, setPlayButtonPosition] = useState([-4.2, 12, 1])
  const [explodeButtonPosition, setExplodeButtonPosition] = useState([-2, -8, 5])
  const [lockButtonPosition, setLockButtonPosition] = useState([-4.6, 3.8, -1])

  const [playButtonStyles, setPlayButtonStyles] = useSpring(() => ({ opacity: 0 }))
  const [explodeButtonStyles, setExplodeButtonStyles] = useSpring(() => ({ opacity: 0 }))
  const [lockButtonStyles, setLockButtonStyles] = useSpring(() => ({ opacity: 0 }))

  useEffect(() => {
    if (isGrabbed) {
      if (svgPlayPointAnimSVGRef.current && location === '/play') {
        svgPlayPointAnimSVGRef.current.classList.remove('hoverState')
      }
      if (svgExplodePointAnimSVGRef.current && location === '/explode') {
        svgExplodePointAnimSVGRef.current.classList.remove('hoverState')
      }
      if (svgLockPointAnimSVGRef.current && location === '/lock') {
        svgLockPointAnimSVGRef.current.classList.remove('hoverState')
      }
    } else {
      if (svgPlayPointAnimSVGRef.current && location === '/play') {
        svgPlayPointAnimSVGRef.current.classList.add('hoverState')
      }
      if (svgExplodePointAnimSVGRef.current && location === '/explode') {
        svgExplodePointAnimSVGRef.current.classList.add('hoverState')
      }
      if (svgLockPointAnimSVGRef.current && location === '/lock') {
        svgLockPointAnimSVGRef.current.classList.add('hoverState')
      }
    }
  }, [isGrabbed])

  useEffect(() => {
    setLineAnimating(!isAnimating)
    if (isAnimating === true) {
      setButtonProps1({ opacity: 0, immediate: true })
      setButtonProps2({ opacity: 0, immediate: true })
      setButtonProps3({ opacity: 0, immediate: true })
      setButtonTextProps1({ opacity: 0, immediate: true })
      setButtonTextProps2({ opacity: 0, immediate: true })
      setButtonTextProps3({ opacity: 0, immediate: true })
    } else if (isAnimating === false) {
      setButtonProps1({ opacity: 1, delay: 300 })
      setButtonProps2({ opacity: 1, delay: 450 })
      setButtonProps3({ opacity: 1, delay: 500 })
      setButtonTextProps1({ opacity: 1, delay: 550 })
      setButtonTextProps2({ opacity: 1, delay: 600 })
      setButtonTextProps3({ opacity: 1, delay: 650 })
    }
  }, [
    isAnimating,
    setButtonProps1,
    setButtonProps2,
    setButtonProps3,
    setButtonTextProps1,
    setButtonTextProps2,
    setButtonTextProps3
  ])

  useEffect(() => {
    rayCastObjectScale.current = [1.2, 1.2, 1.2]
    switch (location) {
      case '/':
        if (isMobile) {
          rayCastObjectScale.current = [2, 2, 2]
        }
        setPlayButtonPosition([-4.2, 12, 1])
        setExplodeButtonPosition([-4, -8, 5])
        setLockButtonPosition([-4.6, 3.8, -1])
        break

      case '/lock':
        setPlayButtonPosition([-3.6, 6.0, -0.4])
        setExplodeButtonPosition([-3.6, -3.0, 4])
        if (isMobile) {
          setLockButtonPosition([-2.8, 4, 3])
        } else {
          setLockButtonPosition([-2.8, 2.4, -1])
        }

        break

      case '/play':
        setPlayButtonPosition([0, 14.6, 1])
        setExplodeButtonPosition([4.6, 7.8, 3])
        setLockButtonPosition([4.4, 1.5, 3])
        break

      case '/explode':
        setPlayButtonPosition([0.2, 13, 5.2])
        setExplodeButtonPosition([-5.6, 7.8, 6.4])
        setLockButtonPosition([3.2, -1, 7.4])
        break

      default:
        break
    }
  }, [location])

  useEffect(() => {
    setPlayButtonStyles({ opacity: isMobile ? 0.0 : 1.0, transform: 'translate3d(-50%, -50%, 0px) scale(0.8)' })
    setExplodeButtonStyles({ opacity: isMobile ? 0.0 : 1.0, transform: 'translate3d(-50%, -50%, 0px) scale(0.8)' })
    setLockButtonStyles({ opacity: isMobile ? 0.0 : 1.0, transform: 'translate3d(-50%, -50%, 0px) scale(0.8)' })

    if (svgPlayPointAnimSVGRef.current) {
      svgPlayPointAnimSVGRef.current.classList.remove('hoverState')
    }
    if (svgExplodePointAnimSVGRef.current) {
      svgExplodePointAnimSVGRef.current.classList.remove('hoverState')
    }
    if (svgLockPointAnimSVGRef.current) {
      svgLockPointAnimSVGRef.current.classList.remove('hoverState')
    }

    if (lineAnimating) {
      switch (location) {
        case '/':
          setPlayButtonStyles({ opacity: 1, transform: 'translate3d(-50%, -50%, 0px) scale(1)' })
          setExplodeButtonStyles({ opacity: 1, transform: 'translate3d(-50%, -50%, 0px) scale(1)' })
          setLockButtonStyles({ opacity: 1, transform: 'translate3d(-50%, -50%, 0px) scale(1)' })

          if (svgPlayTextElRef.current) {
            svgPlayTextElRef.current.classList.add('hoverState')
          }
          if (svgExplodeTextElRef.current) {
            svgExplodeTextElRef.current.classList.add('hoverState')
          }
          if (svgLockTextElRef.current) {
            svgLockTextElRef.current.classList.add('hoverState')
          }

          break
        case '/play':
          setPlayButtonStyles({ opacity: 1, transform: 'translate3d(-50%, -50%, 0px) scale(1)' })

          if (svgPlayTextElRef.current) {
            svgPlayTextElRef.current.classList.add('hoverState')
          }

          if (svgPlayPointAnimSVGRef.current) {
            svgPlayPointAnimSVGRef.current.classList.add('hoverState')
          }

          break
        case '/explode':
          setExplodeButtonStyles({ opacity: 1, transform: 'translate3d(-50%, -50%, 0px) scale(1)' })

          if (svgExplodeTextElRef.current) {
            svgExplodeTextElRef.current.classList.add('hoverState')
          }

          if (svgExplodePointAnimSVGRef.current) {
            svgExplodePointAnimSVGRef.current.classList.add('hoverState')
          }

          break
        case '/lock':
          setLockButtonStyles({ opacity: 1, transform: 'translate3d(-50%, -50%, 0px) scale(1)' })

          if (svgLockTextElRef.current) {
            svgLockTextElRef.current.classList.add('hoverState')
          }

          if (svgLockPointAnimSVGRef.current) {
            svgLockPointAnimSVGRef.current.classList.add('hoverState')
          }

          break

        default:
          break
      }
    }
  }, [
    lineAnimating,
    location,
    setExplodeButtonStyles,
    setLockButtonStyles,
    setPlayButtonStyles
  ])

  const svgPlayPointAnimLoad = () => {
    svgPlayPointAnimSVGRef.current = svgPlayPointAnimRef.current.contentDocument.querySelector('svg')
  }

  const svgExplodePointAnimLoad = () => {
    svgExplodePointAnimSVGRef.current = svgExplodePointAnimRef.current.contentDocument.querySelector('svg')
  }

  const svgLockPointAnimLoad = () => {
    svgLockPointAnimSVGRef.current = svgLockPointAnimRef.current.contentDocument.querySelector('svg')
  }

  const svgPlayTextLoad = () => {
    svgPlayTextElRef.current = svgPlayTextRef.current.contentDocument.querySelector('svg')
    if (location === '/') {
      svgPlayTextElRef.current.classList.add('hoverState')
    }
  }
  const svgExplodeTextLoad = () => {
    svgExplodeTextElRef.current = svgExplodeTextRef.current.contentDocument.querySelector('svg')
    if (location === '/') {
      svgExplodeTextElRef.current.classList.add('hoverState')
    }
  }
  const svgLockTextLoad = () => {
    svgLockTextElRef.current = svgLockTextRef.current.contentDocument.querySelector('svg')
    if (location === '/') {
      svgLockTextElRef.current.classList.add('hoverState')
    }
  }

  const clearHoverStates = () => {
    svgPlayTextElRef.current.classList.remove('hoverState')
    svgExplodeTextElRef.current.classList.remove('hoverState')
    svgLockTextElRef.current.classList.remove('hoverState')
  }

  return (
    <>
      <am.mesh position={playButtonPosition}>
        <Sphere
          scale={rayCastObjectScale.current}
          onPointerDown={(e) => {
            e.stopPropagation()
            if (isMobile && location !== '/') {
              return
            }

            setInteracted(true)
            setLocation('/play')
            clearHoverStates()
          }}
          onPointerOver={() => {
            if (isMobile) {
              return
            }

            svgPlayRef.current.dispatchEvent(new window.MouseEvent('pointerover'))

            const svgEl = svgPlayRef.current.contentDocument.querySelector('svg')
            svgEl.classList.add('hoverState')
            svgEl.dispatchEvent(new window.Event('click'))

            if (location === '/') {
              svgPlayTextElRef.current.classList.remove('hoverState')
              setTimeout(() => {
                svgPlayTextElRef.current.classList.add('hoverState')
              }, 200)
            } else {
              svgPlayTextElRef.current.classList.add('hoverState')
            }
          }}
          onPointerOut={() => {
            if (isMobile) {
              return
            }

            svgPlayRef.current.dispatchEvent(new window.MouseEvent('pointerout'))

            const svgEl = svgPlayRef.current.contentDocument.querySelector('svg')
            svgEl.classList.remove('hoverState')

            if (location !== '/play' && location !== '/') {
              svgPlayTextElRef.current.classList.remove('hoverState')
            }
          }}
        >
          <meshBasicMaterial attach='material' depthTest={false} depthWrite={false} transparent opacity={0} />
        </Sphere>
        <AHtml
          // scaleFactor={scaleFactor}
          zIndexRange={[100, 0]}
          center
          style={{ pointerEvents: 'none', ...playButtonStyles }}
        >
          <PlayButton style={buttonProps1}>
            <SVGicon ref={svgPlayRef} type='image/svg+xml' data={isSafari ? playImgPathSafari : playImgPath} />
            <ButtonText style={buttonTextProps1}>
              <SVGText ref={svgPlayTextRef} type='image/svg+xml' onLoad={() => svgPlayTextLoad()} data={playCopy} />
            </ButtonText>
            <SVGPointAnimContainerPlay>
              <SVGPointAnimPlay ref={svgPlayPointAnimRef} type='image/svg+xml' onLoad={() => svgPlayPointAnimLoad()} data={pointAnimPath} />
            </SVGPointAnimContainerPlay>
          </PlayButton>
        </AHtml>
      </am.mesh>
      <am.mesh position={explodeButtonPosition}>
        <Sphere
          onPointerDown={(e) => {
            e.stopPropagation()

            if (isMobile && location !== '/') {
              return
            }

            setInteracted(true)
            setLocation('/explode')
            clearHoverStates()
          }}
          scale={rayCastObjectScale.current}
          onPointerOver={() => {
            if (isMobile) {
              return
            }

            svgExplodeRef.current.dispatchEvent(new window.MouseEvent('pointerover'))

            const svgEl = svgExplodeRef.current.contentDocument.querySelector('svg')
            svgEl.classList.add('hoverState')
            svgEl.dispatchEvent(new window.Event('click'))

            if (location === '/') {
              svgExplodeTextElRef.current.classList.remove('hoverState')
              setTimeout(() => {
                svgExplodeTextElRef.current.classList.add('hoverState')
              }, 200)
            } else {
              svgExplodeTextElRef.current.classList.add('hoverState')
            }
          }}
          onPointerOut={() => {
            if (isMobile) {
              return
            }
            svgExplodeRef.current.dispatchEvent(new window.MouseEvent('pointerout'))

            const svgEl = svgExplodeRef.current.contentDocument.querySelector('svg')
            svgEl.classList.remove('hoverState')

            if (location !== '/explode' && location !== '/') {
              // setExplodeButtonStyles({ opacity: 1.0, transform: 'translate3d(-50%, -50%, 0px) scale(0.8)' })
              svgExplodeTextElRef.current.classList.remove('hoverState')
            }
          }}
        >
          <meshBasicMaterial attach='material' depthTest={false} depthWrite={false} transparent opacity={0} />
        </Sphere>
        <AHtml
          // scaleFactor={scaleFactor}
          zIndexRange={[100, 0]}
          center
          style={{ pointerEvents: 'none', ...explodeButtonStyles }}
        >
          <ExplodeButton style={buttonProps2}>
            <SVGicon ref={svgExplodeRef} type='image/svg+xml' data={isSafari ? explodeImgPathSafari : explodeImgPath} />
            <ButtonText style={buttonTextProps2}>
              <SVGText ref={svgExplodeTextRef} type='image/svg+xml' onLoad={() => svgExplodeTextLoad()} data={explodeCopy} />
            </ButtonText>
            <SVGPointAnimContainerExplode>
              <SVGPointAnimExplode ref={svgExplodePointAnimRef} type='image/svg+xml' onLoad={() => svgExplodePointAnimLoad()} data={pointAnimPath} />
            </SVGPointAnimContainerExplode>
          </ExplodeButton>
        </AHtml>
      </am.mesh>
      <am.mesh position={lockButtonPosition}>
        <Sphere
          scale={rayCastObjectScale.current}
          onPointerDown={(e) => {
            e.stopPropagation()
            if (isMobile && location !== '/') {
              return
            }

            setInteracted(true)
            setLocation('/lock')
            clearHoverStates()
          }}
          onPointerOver={() => {
            if (isMobile) {
              return
            }

            svgLockRef.current.dispatchEvent(new window.MouseEvent('pointerover'))

            const svgEl = svgLockRef.current.contentDocument.querySelector('svg')
            svgEl.classList.add('hoverState')
            svgEl.dispatchEvent(new window.Event('click'))

            if (location === '/') {
              svgLockTextElRef.current.classList.remove('hoverState')
              setTimeout(() => {
                svgLockTextElRef.current.classList.add('hoverState')
              }, 200)
            } else {
              svgLockTextElRef.current.classList.add('hoverState')
            }
          }}
          onPointerOut={() => {
            if (isMobile) {
              return
            }

            svgLockRef.current.dispatchEvent(new window.MouseEvent('pointerout'))

            const svgEl = svgLockRef.current.contentDocument.querySelector('svg')
            svgEl.classList.remove('hoverState')

            if (location !== '/lock' && location !== '/') {
              svgLockTextElRef.current.classList.remove('hoverState')
            }
          }}
        >
          <meshBasicMaterial attach='material' depthTest={false} depthWrite={false} transparent opacity={0} />
        </Sphere>
        <AHtml
          // scaleFactor={scaleFactor}
          zIndexRange={[100, 0]}
          center
          style={{ pointerEvents: 'none', ...lockButtonStyles }}
        >
          <LockButton style={buttonProps3}>
            <SVGicon ref={svgLockRef} type='image/svg+xml' data={isSafari ? lockImgPathSafari : lockImgPath} />
            <LockButtonText style={buttonTextProps3}>
              <SVGText ref={svgLockTextRef} type='image/svg+xml' onLoad={() => svgLockTextLoad()} data={lockCopy} />
            </LockButtonText>
            <SVGPointAnimContainerLock>
              <SVGPointAnimLock ref={svgLockPointAnimRef} type='image/svg+xml' onLoad={() => svgLockPointAnimLoad()} data={pointAnimPath} />
            </SVGPointAnimContainerLock>
          </LockButton>
        </AHtml>
      </am.mesh>
    </>
  )
}

const SVGicon = styled(a.object)`
  width: 4.3vw;
  max-width: 85px;

  @media (max-width: 768px) {
    width: 16vw;
  }
  `

const SVGText = styled(a.object)`
  padding-top: 0.4vw;
  width: 4.3vw;
  
  @media (max-width: 768px) {
    width: 16vw;
  }
`

const SVGPointAnimContainerPlay = styled(a.object)`
  position: absolute;
  left: 4.6vw;
  top: -4.2vw;

  @media (max-width: 768px) {
    left: 9.7vw;
    top: -14.3vw;
  }
`

const SVGPointAnimPlay = styled(a.object)`
  width: 10vw;
  transform: rotateZ(-56deg);
  
  @media (max-width: 768px) {
    width: 22vw;
  }
`

const SVGPointAnimContainerExplode = styled(a.object)`
  position: absolute;
  left: 4.6vw;
  top: -4.2vw;

  @media (max-width: 768px) {
    left: 9.7vw;
    top: -14.3vw;
  }
`

const SVGPointAnimExplode = styled(a.object)`
  width: 10vw;
  transform: rotateZ(-56deg);
  
  @media (max-width: 768px) {
    width: 22vw;
  }
`

const SVGPointAnimContainerLock = styled(a.object)`
  position: absolute;
  left: 4.6vw;
  top: -4.2vw;

  @media (max-width: 768px) {
    left: 9.7vw;
    top: -14.3vw;
  }
`

const SVGPointAnimLock = styled(a.object)`
  width: 10vw;
  transform: rotateZ(-56deg);
  
  @media (max-width: 768px) {
    width: 22vw;
  }
`

const Button = styled(a.div)`
  display: block;
  width: 5vw;
  height: 5vw;
  max-height: 115px;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: flex-end;

  @media (max-width: 768px) {
    align-items: center;
  }
`

const PlayButton = styled(Button)`
  
  `

const ExplodeButton = styled(Button)`
  
  `

const LockButton = styled(Button)`
  transform: scaleX(-1);
`

const ButtonText = styled(a.div)`
  font-weight: bold;
  font-size: 1.2rem;
  position: absolute;
  left: 5.4vw;

  @media (max-width: 768px) {
    left: 11.8vw;
  }
`

const LockButtonText = styled(a.div)`
  transform: scaleX(-1);
  font-weight: bold;
  font-size: 1.2rem;
  position: absolute;
  left: -3.8vw;

  @media (max-width: 768px) {
    left: -23vw;
  }
`
