import React, { useState, useEffect } from 'react'
import { a, Spring } from '@react-spring/web'
import styled from 'styled-components'
import { useProgress } from '@react-three/drei'

// images
import futureZPath from '../assets/images/PUMA_Future_Z_logo.svg'

export default () => {
  const { progress } = useProgress()
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (progress === 100) {
      setReady(true)
    }
  }, [progress])

  return (
    <>
      {ready && (

        <Spring
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}
          delay={5000}
        >
          {props => <FutureZImg style={props} src={futureZPath} />}
        </Spring>
      )}

    </>
  )
}

const FutureZImg = styled(a.img)`
  position: absolute;
  top: 2.1vw;
  right: 6vw;
  width: 25vw;
  height: auto;
  z-index: 2;

  @media (max-width: 768px) {
    left: 8vw;
    top: 6vw;
    right: auto;
    width: 44vw;
  }
  `
