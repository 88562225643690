import React, { useEffect, useState } from 'react'
import { a, useSpring } from '@react-spring/web'
import styled from 'styled-components'
import { useProgress } from '@react-three/drei'

import crazyPath from '../assets/images/crazy.gif'

export default () => {
  const { progress } = useProgress()
  const [ready, setReady] = useState(false)
  const [props, setProps] = useSpring(() => ({ opacity: 0 }))

  useEffect(() => {
    if (progress === 100) {
      setReady(true)
    }
  }, [progress])

  useEffect(() => {
    if (ready) {
      setProps({ opacity: 1, delay: 1000 })
      setProps({ opacity: 0, delay: 3700 })
    }
  }, [ready, setProps])

  return (
    <>
      <CrazyImg style={{ ...props }} src={crazyPath} />
    </>
  )
}

const CrazyImg = styled(a.img)`
  position: absolute;
  bottom: 1vw;
  left: 3.2vw;
  width: 36.4vw;
  height: auto;
  z-index: 1;
  opacity: 0;

  @media (max-width: 768px) {
    left: auto;
    bottom: auto;
    top: 4vw;
    right: 3vw;
    width: 60vw;
  }
`
