import React, { useRef, useState, useEffect, useContext } from 'react'
/* eslint-disable react-hooks/exhaustive-deps */
import { useFrame, useLoader, useThree } from 'react-three-fiber'
import { a } from '@react-spring/three'
import { useGLTF, useProgress } from '@react-three/drei'
import { config, useSpring, useTransition, useChain } from '@react-spring/core'
import { LinearEncoding, TextureLoader, Vector2 } from 'three'
import { isMobile } from 'react-device-detect'
// import { useControl } from 'react-three-gui'

// context
import { InteractionContext } from '../state/InteractionContext'

// model
import modelPath from '../assets/models/PumaFutureZ11_TexIncluded_02.glb'

// textures
import specPath from '../assets/textures/roughnessMaps/specMap.jpg'
import bumpPath from '../assets/textures/bumpMaps/bumpMap.jpg'

// components
import CTA from './CTA'
import { useLocation } from 'wouter'

export default ({ position, rotation }) => {
  const ref = useRef()
  const refStuds = useRef()
  const refIndex = useRef(0)
  const locations = useRef(['/', '/lock', '/explode', '/play'])

  const [location, setLocation] = useLocation()

  const { setInteracted, isGrabbed, setIsGrabbed } = useContext(InteractionContext)

  const { mouse } = useThree()

  const { scene } = useGLTF(modelPath)
  const { progress } = useProgress()
  const [ready, setReady] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)

  const specMap = useLoader(TextureLoader, specPath)
  specMap.flipY = false
  const bumpMap = useLoader(TextureLoader, bumpPath)
  bumpMap.flipY = false

  const [grabPoint, setGrabPoint] = useState(new Vector2())
  const [parentProps, setParentProps] = useSpring(() => ({ position: [0, 0, 0], config: config.stiff }))
  const [bootScale, setBootScale] = useSpring(() => ({ scale: [0.8, 0.8, 0.8], config: config.stiff }))
  const [grabRotation, setGrabRotation] = useSpring(() => ({ rotation: [0, 0, 0], config: config.stiff }))
  const [localRotation, setLocalRotation] = useSpring(() => ({ rotation: [0, 0, 0], config: config.molasses }))

  const localRotationCount = useRef(0)

  const dragStart = useRef(new Vector2())

  const isDragging = useRef(false)

  const seenRoutes = useRef([])

  if (isMobile) {
    useEffect(() => {
      window.addEventListener('touchstart', function (e) {
        dragStart.current.copy(mouse)
      })

      window.addEventListener('touchend', function (e) {
        isDragging.current = false
        dragStart.current.copy(mouse)
      })

      window.addEventListener('touchmove', function (e) {
        if (isDragging.current === true) {
          return
        }

        if (mouse.x === -1) {
          return
        }

        const maxDragDistance = 0.5
        const delta = mouse.x - dragStart.current.x

        if (Math.abs(delta) > maxDragDistance) {
          isDragging.current = true

          if (delta > maxDragDistance) {
            refIndex.current -= 1
          }
          if (delta < -maxDragDistance) {
            refIndex.current += 1
          }

          if (refIndex.current < 0) {
            refIndex.current = 3
          }

          if (refIndex.current > 3) {
            refIndex.current = 0
          }

          setInteracted(true)

          const newLocation = locations.current[refIndex.current]

          seenRoutes.current[newLocation] = true

          if (newLocation === '/' && Object.keys(seenRoutes.current).length > 3) {
            window.location.reload()
          } else {
            setLocation(newLocation)
          }
        }
      })
    }, [])
  }

  const usePreventScroll = (preventScrollRef) => {
    useEffect(() => {
      const preventScrolling = e => {
        if (preventScrollRef.current) {
          e.preventDefault()
        }
      }

      document.addEventListener('touchmove', preventScrolling, {
        passive: false
      })
      return () => document.removeEventListener('touchmove', preventScrolling)
    }, [preventScrollRef])
  }

  const preventScrollRef = useRef(false)
  usePreventScroll(preventScrollRef)

  useEffect(() => {
    if (progress === 100) {
      setReady(true)
    }
  }, [progress])

  // const TAU = (Math.PI * 2) * 2
  // const rotX = useControl('Rotation X', { type: 'number', value: rotation[0], min: -TAU, max: TAU, group: 'Boot Rotation' })
  // const rotY = useControl('Rotation Y', { type: 'number', value: rotation[1], min: -TAU, max: TAU, group: 'Boot Rotation' })
  // const rotZ = useControl('Rotation Z', { type: 'number', value: rotation[2], min: -TAU, max: TAU, group: 'Boot Rotation' })

  // const posX = useControl('Pos X', { type: 'number', value: position[0], min: -30, max: 30, group: 'Boot Position' })
  // const posY = useControl('Pos Y', { type: 'number', value: position[1], min: -30, max: 30, group: 'Boot Position' })
  // const posZ = useControl('Pos Z', { type: 'number', value: position[2], min: -30, max: 30, group: 'Boot Position' })

  const transitionRef = useRef()
  const transition = useTransition(ready, {
    from: { position: [0, -40, -10], rotation: [1.6, -2.6, 1.6] },
    enter: { position: [0, 0, 0], rotation: [0, 0, 0] },
    config: {
      duration: 800
    },
    ref: transitionRef,
    onStart: () => setIsAnimating(true),
    onRest: () => setIsAnimating(false)
  })

  useChain([transitionRef], [4.56])

  // const springParams = useSpring({
  //   position: [posX, posY, posZ],
  //   rotation: [rotX, rotY, rotZ],
  //   immediate: true
  // })

  const springParams = useSpring({
    position: position,
    rotation: rotation,
    config: config.molasses,
    onStart: () => {
      setIsAnimating(true)
    },
    onRest: () => setIsAnimating(false)
  })

  useEffect(() => {
    localRotationCount.current += (Math.random() > 0.5) ? 1 : -1
    setLocalRotation({ rotation: [0, (Math.PI * 2) * localRotationCount.current, 0] })
  }, [location])

  useEffect(() => {
    // ref.current.material.roughnessMap = specMap
    ref.current.material.bumpMap = bumpMap
    ref.current.material.bumpScale = 0.08

    ref.current.geometry.rotateY(Math.PI / 2)
    ref.current.geometry.rotateX(-Math.PI / 2)
    ref.current.geometry.translate(0, 0, 0.07)
    ref.current.geometry.scale(100, 100, 100)

    refStuds.current.material.opacity = 1
    // refStuds.current.material.roughnessMap = specMap
    // refStuds.current.material.bumpMap = bumpMap
    // refStuds.current.material.bumpScale = 0.1

    refStuds.current.geometry.rotateY(Math.PI / 2)
    refStuds.current.geometry.rotateX(-Math.PI / 2)
    refStuds.current.geometry.translate(0, 0, 0.07)
    refStuds.current.geometry.scale(100, 100, 100)
  }, [bumpMap, specMap])

  useEffect(() => {
    if (ready) {
      const startTime = 5100
      setBootScale({ scale: [0.92, 0.92, 0.92], delay: startTime })
      setBootScale({ scale: [0.8, 0.8, 0.8], delay: startTime + 250 })
      setBootScale({ scale: [0.87, 0.87, 0.87], delay: startTime + 650 })
      setBootScale({ scale: [0.8, 0.8, 0.8], delay: startTime + 825 })
    }
  }, [ready, setBootScale])

  useEffect(() => {
    window.addEventListener('mouseup', () => {
      setIsGrabbed(false)
    })
    window.addEventListener('touchend', () => {
      setIsGrabbed(false)
    })
  }, [])

  useEffect(() => {
    if (isGrabbed === false) {
      setParentProps({ position: [0, 0, 0] })
      setGrabRotation({ rotation: [0, 0, 0] })
    }

    preventScrollRef.current = isGrabbed
  }, [isGrabbed, setParentProps, setGrabRotation])

  useEffect(() => {
    scene.traverse(function (child) {
      if (child.type === 'Mesh' || child.type === 'SkinnedMesh') {
        child.material.metalness = 0
        if (child.material.map !== null) {
          child.material.map.encoding = LinearEncoding
        }
      }
    })
  }, [scene])

  useFrame(() => {
    if (isGrabbed) {
      const newPosx = mouse.x - grabPoint.x
      const newPosY = mouse.y - grabPoint.y
      setParentProps({ position: [newPosx * 10, newPosY * 10, 2] })
      setGrabRotation({ rotation: [0, newPosx * 2 + newPosY * 2, 0] })
    }
  })

  return transition(({ ...props }) => (
    <a.group
      {...parentProps}
      onPointerDown={(e) => {
        e.stopPropagation()
        if (e.button === 0) {
          setGrabPoint(grabPoint.copy(mouse))
          setIsGrabbed(true)
        }
      }}
      onPointerUp={(e) => {
        if (e.button === 0) {
          setIsGrabbed(false)
        }
      }}
    >
      <a.group {...props}>
        <a.group
          position={springParams.position}
          rotation={springParams.rotation}
          {...bootScale}
        >
          <a.group
            {...localRotation}
          >
            <a.mesh
              {...grabRotation}
            // position={[posX, posY, posZ]}
            // rotation={[rotX, rotY, rotZ]}
              ref={ref}
              geometry={scene.children[0].children[0].children[0].geometry}
              material={scene.children[0].children[0].children[0].material}
            >
              <CTA isAnimating={isAnimating} />
            </a.mesh>
            <a.mesh
              {...grabRotation}
              ref={refStuds}
              geometry={scene.children[0].children[0].children[1].geometry}
              material={scene.children[0].children[0].children[1].material}
            />
          </a.group>
        </a.group>
      </a.group>
    </a.group>
  ))
}
